import { navigate } from 'gatsby';
import React from 'react';
import Container from '../components/atoms/container';
import Button from '../components/atoms/button';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import Routes from '../services/routes';
import styled from 'styled-components';
import { FULL_PAGE_HEIGHT } from '../services/sizeHelper';
import bgImage from '../images/panbox-familj.png';
import useScreenClassHelper from '../hooks/useScreenClassHelper';

const Wrapper = styled.div`
  min-height: ${FULL_PAGE_HEIGHT};
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 10;
`;

const Image = styled.img<{ isMobile: boolean }>`
  height: ${({ isMobile }) => (isMobile ? 'auto' : '500px')};
  width: ${({ isMobile }) => (isMobile ? '80%' : 'auto')};
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: ${({ isMobile }) => (isMobile ? '-12px' : '-22px')};
  left: 0;
  right: 0;
`;

const WelcomePage = () => {
  const { IS_MOBILE } = useScreenClassHelper();

  const handleButtonClick = () => {
    navigate(Routes.DASHBOARD);
  };

  return (
    <Wrapper>
      <Spacer spacing={IS_MOBILE ? 8 : 15} />
      <StyledContainer>
        <Typography color="textSecondary" variant="header" align="center">
          Välkommen till panBox!
        </Typography>
        <Spacer spacing={2} />
        <Button onClick={handleButtonClick}>Till mina sidor</Button>
      </StyledContainer>
      <Image isMobile={IS_MOBILE} src={bgImage} alt="familj" />
    </Wrapper>
  );
};

export default WelcomePage;
